.header {
  background-color: #0096fa;
  width: 100%;
  height: 94px;
  outline: 3px solid #de3700;
  position: sticky;
  top: 0;
  z-index: 100;

  span {
    color: white;
    margin-left: 10px;
    font-size: 30px;
  }

  .info-box {
    color: white;
    margin-left: 10px;
    font-size: 15px;
  }

  .case-info {
    float: right;
    margin-right: 10px;

    span {
      margin-right: 10px;
    }
  }

  .sections {
    background-color: rgba(0, 150, 250, 0.4);
    a {
      padding: 0px 10px;
      color: #0e4275;
    }
  }
}
