.cart {
  .section {
    h4 {
      padding: 20px 0;
      color: #0091c4;
      font-size: 21px;
      text-transform: capitalize;
    }

    .section-items {
      padding: 10px;
      border-bottom: 0.0625rem dotted rgba(119, 119, 119, .2);
      border-top: 0.0625rem solid rgba(51, 51, 51, .8);

      .row {
        margin-bottom: 16px;
      }

      .item {
        color: rgba(51, 51, 51, .8);
        font-weight: 600;
      }

      .credit {
        color: #0096fa;;
      }

    }

    .section-total {
      span {
        color: rgba(51, 51, 51, .8);
        font-weight: 600;

        span {
          font-weight: 300;
          font-size: .8125rem;
          color: rgba(51, 51, 51, .8);
        }
      }
    }
  }

  .total {
    color: rgba(51, 51, 51, .8);
    font-weight: 600;
  }
}
