.main-container {
  margin-bottom: 50px;

  .form-panel {
    position: sticky;
    overflow: auto;
    max-height: 90vh;
  }

  .login-form {
    border: .2rem solid #ececec;
    padding: 20px;
    border-radius: 10px;
  }

  .cases-table {
    overflow: auto;
    max-height: 70vh;
  }

  .btn-primary {
    --bs-btn-bg: #005dac;
  }

}

.loading {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;

  div {
    margin-top: 25vh;
  }

  .loading-container {
    margin-top: 0;
    text-align: center;
  }

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 2;
  cursor: pointer;
}
