$color_1: #666;
$color_2: #154b8e;
$color_3: #369;
$color_4: #154a8e;
$color_5: #fff;
$color_6: #454545;
$font-family_1: "Proxima Nova", Arial, sans-serif;
$font-family_2: "FontAwesome";
$background-color_1: #f6f6f6;
$border-color_1: #e9e9e9;
$border-color_2: #fff;

.scheduling {
  .column {
    color: $color_1;
    width: 49%;
    display: inline-block;
  }

  .column.column-first {
    padding-right: 4%;
  }

  .column.column-last {
    padding-left: 4%;
  }

  .installation-container {
    padding-top: 20px;

    .column {
      border: 0;

      h3 {
        font-weight: bold;
      }

      .ordering-schedule-title {
        margin: 6rem 0 2rem;
        padding: .2em .2em 0;
        text-align: center;
      }

      .ordering-selected-date {
        display: none;

        h3 {
          font-size: 16px;
          line-height: 1.2;
          font-weight: normal;
          margin: 1rem 0;
        }

        span {
          color: $color_4;
          font-size: 22px;
          line-height: 1.2;
          font-weight: bold;
        }
      }

      .guide {
        margin: 1rem 0;
      }

      .ordering-time {
        display: none;
      }

      .ordering-time__label {
        font-size: 16px;
        line-height: 1.2;
        font-weight: normal;
        margin: 3rem 0 1rem;
      }

      .ordering-time__option {
        border: 1px solid #154a8e;
        color: $color_4;
        display: block;
        font-weight: bold;
        margin: 0 0 1rem;
        padding: 1rem 2rem;
        position: relative;

        span {
          border: 1px solid #154a8e;
          border-radius: 50%;
          display: inline-block;
          height: 10px;
          margin: 0 2rem 0 0;
          position: relative;
          width: 10px;
          vertical-align: middle;
        }
      }

      .ordering-time__option--active {
        background: #154a8e;
        color: $color_5;
        position: relative;

        &:after {
          //background: url('/themes/custom/hughes_theme/css/../images/check_box_white.svg') no-repeat center center;
          content: '';
          position: absolute;
          height: 15px;
          right: 2rem;
          top: 50%;
          -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
          width: 15px;
        }

        span {
          border-color: $border-color_2;

          &:after {
            background: #fff;
            border-radius: 50%;
            content: '';
            display: block;
            height: 5px;
            left: 50%;
            position: absolute;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            top: 50%;
            width: 5px;
          }
        }
      }
    }
  }

  .table-container {
    td {
      border: 0;
      padding: 0;

      &:last-child {
        &:not(:only-child) {
          text-align: right;
        }
      }
    }

    tr {
      h4 {
        color: $color_2;
      }

      h4.sub {
        margin-top: 10px;
      }
    }

    tr.total {
      border: 0;

      h4 {
        float: left;
      }

      td {
        padding: 20px 0;
        font-weight: bold;

        .tooltip-link {
          margin-left: 15px;

          i.fa {
            vertical-align: middle;
          }
        }
      }
    }

    tr.section {
      td {
        padding-bottom: 8px;
      }
    }

    tfoot {
      border-top: 1px solid #ccc;
    }

    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .ui-widget.ui-widget-content {
    border: 0;
  }

  .ui-datepicker {
    width: 100%;
    font-family: $font-family_1;

    .ui-datepicker-title {
      margin: 0 2.3em;
      line-height: 1.8em;
      text-align: center;
      font-size: 22px;
    }

    .ui-datepicker-header {
      border: 0;
      background: none;
      color: $color_3;

      a {
        color: $color_3;
      }

      .ui-icon {
        background: none;
        width: auto;
        height: 20px;
        text-indent: 0;
        font-weight: normal;
      }

      .ui-state-hover {
        background: none;
        border: 0;
      }

      .ui-datepicker-prev {
        top: 10px;
        left: 0;
      }

      .ui-datepicker-prev-hover {
        top: 10px;
        left: 0;
      }

      .ui-datepicker-next {
        top: 10px;
        right: 30px;
      }

      .ui-datepicker-next-hover {
        top: 10px;
        right: 30px;
      }

      //.ui-icon-circle-triangle-w {
      //  &:before {
      //    font-family: $font-family_2;
      //    position: absolute;
      //    font-weight: normal;
      //    -webkit-box-align: center;
      //    -ms-flex-align: center;
      //    align-items: center;
      //    -webkit-box-pack: center;
      //    -ms-flex-pack: center;
      //    justify-content: center;
      //    top: 2px;
      //    left: 0;
      //    content: "\f053";
      //  }
      //
      //  padding-left: 18px;
      //}

      //.ui-icon-circle-triangle-e {
      //  &:after {
      //    font-family: $font-family_2;
      //    position: absolute;
      //    font-weight: normal;
      //    -webkit-box-align: center;
      //    -ms-flex-align: center;
      //    align-items: center;
      //    -webkit-box-pack: center;
      //    -ms-flex-pack: center;
      //    justify-content: center;
      //    top: 2px;
      //    right: 0;
      //    content: "\f054";
      //  }
      //
      //  padding-right: 18px;
      //}
    }

    .ui-datepicker-year {
      display: none;
    }

    .ui-datepicker-other-month.ui-state-disabled {
      .ui-state-default {
        background: #666;
      }
    }

    th {
      font-weight: normal;
      color: $color_1;
      background: none;
      width: 56px;
    }

    td {
      padding: 0;

      span {
        display: block;
        padding: 0.2em;
        text-align: right;
        text-decoration: none;
      }

      p {
        display: block;
        padding: 0.2em;
        text-align: right;
        text-decoration: none;
        margin: 0;
        cursor: pointer;
      }
    }

    .ui-state-disabled {
      .ui-state-default {
        background: none;
      }
    }

    .ui-datepicker-other-month {
      background: #f6f6f6;
      opacity: 1;
    }

    .ui-state-disabled.disabled-date {
      opacity: 1;

      .ui-state-default {
        background: #f6f6f6;
        color: $color_1;
      }
    }

    .ui-state-default {
      border-color: $border-color_1;
      color: $color_1;
      height: 56px;
      line-height: 56px;
      text-align: center;
      vertical-align: middle;
      background: none;
    }

    .ui-state-hover {
      color: $color_4;
      font-weight: bold;
    }

    .ui-state-active {
      background: #154a8e !important;
      color: $color_5 !important;
      border-bottom: 0 !important;

      td {
        text-align: center;
        padding: 20px !important;
      }
    }

    .availableTimes {
      background: #154a8e !important;
      color: $color_5 !important;
      border-bottom: 0 !important;

      td {
        text-align: center;
        padding: 20px !important;
      }

      .time-option {
        width: 40%;
        display: inline-block;
        padding: 5px 0;
      }
    }

    input[type='radio'] {
      &:checked + label {
        font-weight: bold;
      }
    }
  }

  #datepicker {
    position: relative;
  }

  #no-appointments {
    background-color: $background-color_1;
    display: none;
    position: absolute;
    padding: 70px;
    text-align: center;
    z-index: 100;

    p {
      position: relative;
      top: 25%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      color: $color_4;
      font-weight: bold;
    }
  }

  .required-label {
    color: $color_1;
    position: absolute;
    bottom: 3rem;
  }

  .ui-state-active {
    border: 1px solid #003eff;
    background: #007fff;
    font-weight: normal;
    color: $color_5;
    border: 1px solid #003eff;
    background: #007fff;
    font-weight: normal;
    color: $color_5;
  }

  .hughes-ordering-system-base {
    .scheduling {
      .ui-datepicker {
        .ui-state-active {
          background: #154a8e;
          color: $color_5;
          border-bottom: 0;
        }

        .ui-state-default {
          border-color: $border-color_1;
          color: $color_1;
          height: 56px;
          line-height: 56px;
          text-align: center;
          vertical-align: middle;
          background: none;
        }
      }
    }
  }

  .ui-widget-content {
    .ui-state-active {
      border: 1px solid #003eff;
      background: #007fff;
      font-weight: normal;
      color: $color_5;
    }

    .ui-state-default {
      border: 1px solid #c5c5c5;
      background: #8bea78;//#b4f7b4
      font-weight: normal;
      color: $color_6;
    }
  }

  .ui-widget-header {
    .ui-state-active {
      border: 1px solid #003eff;
      background: #007fff;
      font-weight: normal;
      color: $color_5;
    }

    .ui-state-default {
      border: 1px solid #c5c5c5;
      background: #8bea78;
      font-weight: normal;
      color: $color_6;
    }
  }

  a.ui-button {
    &:active {
      border: 1px solid #003eff;
      background: #007fff;
      font-weight: normal;
      color: $color_5;
    }
  }

  .ui-button {
    &:active {
      border: 1px solid #003eff;
      background: #007fff;
      font-weight: normal;
      color: $color_5;
    }

    border: 1px solid #c5c5c5;
    background: #8bea78;
    font-weight: normal;
    color: $color_6;
  }

  .ui-button.ui-state-active {
    &:hover {
      border: 1px solid #003eff;
      background: #007fff;
      font-weight: normal;
      color: $color_5;
    }
  }

  .ui-state-default {
    border: 1px solid #c5c5c5;
    background: #8bea78;
    font-weight: normal;
    color: $color_6;
  }
}

.ui-widget-content {
  .ui-state-active {
    border: 1px solid #003eff;
    background: #007fff;
    font-weight: normal;
    color: $color_5;
  }
}

.ui-widget-header {
  .ui-state-active {
    border: 1px solid #003eff;
    background: #007fff;
    font-weight: normal;
    color: $color_5;
  }
}

a.ui-button {
  &:active {
    border: 1px solid #003eff;
    background: #007fff;
    font-weight: normal;
    color: $color_5;
  }
}

.ui-button {
  &:active {
    border: 1px solid #003eff;
    background: #007fff;
    font-weight: normal;
    color: $color_5;
  }
}

.ui-button.ui-state-active {
  &:hover {
    border: 1px solid #003eff;
    background: #007fff;
    font-weight: normal;
    color: $color_5;
  }
}

.hughes-ordering-system-base {
  .scheduling {
    .ui-datepicker {
      .availableTimes {
        background: #154a8e;
        color: $color_5;
        border-bottom: 0;
      }
    }
  }
}

html {
  .scheduling {
    .ui-button.ui-state-disabled {
      &:hover {
        border: 1px solid #c5c5c5;
        background: #8bea78;
        font-weight: normal;
        color: $color_6;
      }

      &:active {
        border: 1px solid #c5c5c5;
        background: #8bea78;
        font-weight: normal;
        color: $color_6;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .scheduling {
    .column {
      display: block;
      width: 100%;
      padding: 0 !important;
    }

    #no-appointments {
      padding: 25px;
    }

    .installation-container {
      .column {
        .availableTimes {
          display: none;
        }

        .ordering-selected-date--active {
          display: block;
        }

        .ordering-time--active {
          display: block;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .scheduling {
    .installation-container {
      .column {
        .ordering-schedule-title {
          display: none;
        }

        .guide {
          display: none;
        }
      }
    }
  }
}
