.scripts {
  padding: 10px 10px;
  margin-top: 20px;
  max-height: 90vh;
  overflow: auto;
  position: sticky;

  .offer-content {
    * {
      outline: none;
      font-size: 16px;
    }
  }
}
